@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

body {
  background-color: #3262a8;
}

* {
  font-family: lato, sans-serif;
}

/* Styling for the Navigation section  */
.nav-section {
  display: flex;
  justify-content: space-between;
  background-color: #274d82;
}

.disp-date ul {
  width: 100%;
  display: flex;
  color: white;
  margin: 1.5rem 0 1.5rem 0;
  padding: 0 0 0 1.5rem;
  list-style: none;
}

.micandsetting li {
  margin-right: 2rem;
}

.disp-date ul li {
  margin-top: 5px;
}

.disp-date h3 {
  margin: 0;
  padding: 0 0 0 0.5rem;
  font-size: 1.3rem;
}

.back-logo {
  margin-right: 5%;
}

.micandsetting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  text-decoration: none;
}

a {
  text-decoration: none;
  font-size: 1.1rem;
  color: white;
}

.micandsetting li.active {
  padding: 10px;
  background-color: #b38e1f;
  color: white;
}

.disp-date li.active {
  .fa-circle-chevron-left {
    color: #b38e1f;
  }
}

/* Styling for the Navigation section  */

/* Styling tfor the Home Section  */
.homeTop-container {
  display: flex;
  margin: 1rem 2rem;
}

.image-home {
  display: none;
}

.currency-Container {
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.conv-Gra-Rat,
.Avg-His {
  display: flex;
  color: antiquewhite;
  text-align: center;
}

.calcImg,
.graphImg,
.ratesImg {
  width: 40%;
  height: 75px;
}

.monthlyImg,
.currHistoryImg,
.tipsImg {
  width: 30%;
  height: 70px;
}

.facaret-one {
  color: antiquewhite;
  font-size: 5rem;
}

.facaret-two {
  color: rgb(148, 127, 56);
  font-size: 5rem;
}

.facaret-three {
  color: rgb(113, 179, 47);
  font-size: 5rem;
}

.facaret-four {
  color: rgb(101, 52, 145);
  font-size: 5rem;
}

.facaret-five {
  color: rgb(57, 188, 118);
  font-size: 5rem;
}

.facaret-six {
  color: rgb(40, 172, 159);
  font-size: 5rem;
}

.conv-Gra-Rat div,
.Avg-His div {
  background-color: #3f5471;
  margin-right: 2%;
  margin-bottom: 4%;
  width: 50%;
  height: 200px;
  cursor: pointer;
}

.homeSearchForm {
  margin-bottom: 2rem;
  text-align: left;
  margin-left: 2%;
  display: flex;

  /* flex-direction: column; */
}

.homeSearchForm input {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.5rem;
  width: 50%;
  margin-right: 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}

.homeSearchForm button {
  width: 5rem;
  font-size: 0.8rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.5rem;
  background-color: #3f5471;
  color: azure;
}

.conv-Gra-Rat div:hover,
.Avg-His div:hover {
  background-color: rgb(108, 108, 81);
  width: 60%;

  .fa-caret-down {
    color: #3262a8;
  }
}

.homeSearchForm button:hover {
  background-color: rgb(25, 199, 141);
  color: black;
}

.currency-Container form button:hover {
  background-color: rgb(58, 117, 99);
}

/* Styling tfor the Home Section  */

/* Styling for the Symbols Currency Section  */
.base-curr {
  text-align: center;
  font-size: 2rem;
  color: bisque;
}

.uro-ul {
  margin-left: 5%;
  margin-right: 5%;
}

.uro-ul .detail-li {
  background-color: #86b3f3;
  margin-bottom: 1rem;
  width: 45%;
  height: 6rem;
  margin-right: 1rem;
  font-size: 1.2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 10px;
}

.symbole-code {
  color: greenyellow;
}

.Symbols-Container::-webkit-scrollbar,
.latest-container ul::-webkit-scrollbar,
.EU-VAT-Container .eu-vat-ul::-webkit-scrollbar {
  display: none;
}

/* Styling for the Latest Symbols Currency Section  */
@media screen and (min-width: 768px) {
  .disp-date h3 {
    font-size: 1.5rem;
  }

  .image-home {
    width: 40%;
    margin-right: 2%;
    display: block;
  }

  .calcImg,
  .graphImg,
  .ratesImg {
    width: 30%;
  }

  .homeSearchForm input {
    width: 20%;
  }

  .homeSearchForm button {
    width: 6rem;
    font-size: 1rem;
  }

  .base-curr {
    font-size: 3rem;
  }

  .uro-ul .detail-li {
    width: 22%;
    height: 8rem;
  }
}
