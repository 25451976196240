/* Historical Rate Styling */
.historycontainer {
  background-color: #3f5471;
  margin-top: 2rem;
  text-align: center;
  width: 90%;
  border-radius: 2rem;
  height: 30rem;
  margin-left: 3%;
}

.historycontainer input {
  width: 50%;
  height: 2rem;
  padding-left: 10px;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.btn2 {
  text-align: right;
}

.btn2 button {
  border-radius: 10px;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-right: 23%;
  color: white;
  background-color: #2067d1;
}

.descrip {
  margin-left: 1rem;
}

.historycontainer ul {
  list-style: none;
  height: 250px;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.historycontainer ul li {
  background-color: #86b3f3;
  margin-bottom: 1rem;
  margin-right: 4rem;
  margin-left: 2rem;
  color: black;
  border-radius: 5px;
}

/* Historical Rate Styling */
@media screen and (min-width: 768px) {
  .historycontainer {
    width: 40%;
    margin-left: 5%;
  }
}
